import React, { Component } from 'react'

import SEO from "../components/seo"

class Contact extends Component {

  render() {

    let { options } = this.props.data.settings

    return (
      <>
        <SEO title='Contact' bodyClass='page--contact' />
        <section className='contact__content'>
          <div className='container'>
            <div className='col col-lrg-third'>
              <p style={{marginBottom: '3rem'}}>If you would like to find out more about Peak please contact:</p>
            </div>
            <div className='col col-75 col-lrg-2thirds'>
              <p>
                Peak Marketing & Communications<br />
                { options.address }
              </p>
              <p>
                <a href={`mailto:${options.email}`}>{ options.email }</a><br />
                <a href={`tel:${options.phone}`}>{ options.phone }</a>
              </p>
              <p>
                <a href={ options.linkedin } target='_blank' rel='nofollow noopener noreferrer'>LinkedIn</a><br />
                <a href={ options.twitter } target='_blank' rel='nofollow noopener noreferrer'>Twitter</a>
              </p>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Contact

export const contactQuery = graphql`
  query {
    settings: wordpressAcfOptions(wordpress_id:{eq:"acf"}) {
      options {
        address
        email
        phone
        linkedin
        twitter
      }
    }
  }
`
